.header {
    width: 100%;
    overflow-x: hidden;
}

.top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 5px 30px;
}

.logo img {
    width: 250px;
    height: 30px;
}

.nav {
    display: flex;
    gap: 20px;
}

.nav a {
    color: #000;
    text-decoration: none;
    font-weight: bold;
}

.nav a:hover {
    color: #00ff00; /* green underline */
}

.banner {
    background: url('../../assets/hero-img.png') no-repeat center center/cover;
    color: white;
    height: 80vh;
    display: flex;
    align-items: center;
    padding: 0 30px;
    width: 100%; 
    box-sizing: border-box;
}

.content {
    width: 100%;
    text-align: left;
    max-width: 600px;
}

.content h1 {
    font-size: 55px;
    font-weight: 300;
    line-height: 1.4;
    margin: 0;
}

.content .header-cta-button {
    background-color: #008f00;
    width: 200px !important;
    color: #fff;
    padding: 15px 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
}

.header-cta-button:hover {
    background-color: #00cc00;
}

.section-title {
    background-color: #333;
    color: white;
    padding: 10px 30px;
    text-align: left;
    position: relative;
    border-bottom: 1px solid silver;
}

.section-title h2 {
    margin: 0;
    font-size: 12px !important;
    color: #fff !important;
    font-weight: lighter;
    font-weight: 500;
    display: inline-block;
    position: relative;
    padding-bottom: 10px; 
}

.section-title h2::after {
    content: "";
    display: block;
    height: 2px;
    background-color: #00ff00;
    width: 150px; 
    margin-top: 15px;
    margin-bottom: -20px;
}

@media (max-width: 768px) {
    .banner {
        justify-content: center;
        text-align: center;
    }

    .content {
        max-width: 100%;
    }

    .content h1 {
        font-size: 35px;
    }

    .header-cta-button {
        font-size: 16px;
        padding: 8px 16px;
    }

    .section-title {
        text-align: left;
    }

    .section-title h2 {
        font-size: 12px;
    }

    .section-title h2::after {
        width: 125px;
    }
}
