.opportunities {
    padding: 20px;
  }
  
  .opportunities h2 {
    margin-bottom: 20px;
  }

  .opportunities h3 {
    background: linear-gradient(45deg, rgb(96, 40, 109), rgb(150, 16, 165));
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 500;
    font-size: 20px;
  }
  
  .opportunities-box {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;    
    text-align: left;
    margin-bottom: 40px; 
  }
  
  .opportunities-box-img {
    margin-bottom: 20px;
  }
  
  .opportunities-box-img img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .opportunities-box-text h3 {
    margin: 0 0 10px;
    font-size: 20px;
  }
  
  .opportunities-box-text p {
    font-size: 16px;
    color: #555;
  }
  
  @media (min-width: 769px) {
    .opportunities {
      padding: 20px 100px;
    }

    .opportunities h2{
      font-size: 50px;
    }

    .opportunities h3 {
      font-size: 30px;
      font-weight: 400;
    }
    
    .opportunities-box {
      flex-direction: row;
      gap: 40px; 
      align-items: center;
    }
    
    .opportunities-box:nth-child(even) {
      flex-direction: row-reverse;
    }
    
    .opportunities-box-img {
      flex: 3; 
      margin-bottom: 0;
      margin-right: 20px;
    }
    
    .opportunities-box:nth-child(even) .opportunities-box-img {
      margin-right: 0;
      margin-left: 20px;
    }
    
    .opportunities-box-text {
      flex: 3;
    }
  }
  