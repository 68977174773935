.why-join-section {
  /* padding: 0 35px; */

  max-width: 1280px;
  margin: 50px auto;
  padding: 0 20px;
}

.community-container {
  position: relative;
}
/* .community-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 20px;
  max-width: 800px;
  width: 100%;
}
 */

.image-container {
  width: 100%;
}

.community-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.text-container {
  align-self: flex-start;
  max-width: 800px;
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* White background with some transparency */
  padding: 20px;
  border-radius: 8px;
  margin-top: -40px; /* Slight overlap with the image */
  margin-left: 0px; /* Position text at the bottom left corner */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for better separation */
  position: absolute;
  bottom: 0px;
  left: 0px;
  text-align: left;
}

.text-container h2 {
  font-size: 30px;
  background: linear-gradient(45deg, rgb(96, 40, 109), rgb(150, 16, 165));
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 300;
  margin-bottom: 10px;
}

.text-container p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

/* Reasons.css */
.reasons-container {
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.reasons-box {
  border: 1px solid #f8f8f8;
  padding: 60px;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reason-img-box {
  width: 50px;
  height: 50px;
}

.reasons-box img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.reason-text h3 {
  /* background: linear-gradient(45deg, rgb(96, 40, 109), rgb(150, 16, 165)); */
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}

.reason-text p {
  font-size: 16px;
  color: #555;
}


@media (max-width: 1024px) {
  .reasons-container {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .reasons-container {
    grid-template-columns: 1fr;
  }
  .text-container {
    position: static;
    margin-top: 20px;
  }
}
