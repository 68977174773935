.form-container {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.form-container h2 {
  color: rgb(75, 40, 109);
  font-weight: 300;
  font-size: 2.5rem;
  text-align: center;
}

.form-container p {
  text-align: center;
  margin-bottom: 20px;
}

form fieldset {
  border: none;
  padding: 0;
}

form legend {
  font-weight: bold;
  font-size: 1.125rem;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.name-fields,
.address-fields,
.contact-fields,
.upload-fields {
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  gap: 10px;
}

.name-fields input,
.address-fields input,
.contact-fields input,
.upload-fields input  {
  flex: 1;
  width: 100%;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

button {
  width: 100%;
  padding: 15px;
  border: none;
  background-color: #029b42;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.125rem;
}

button:hover {
  background-color: #0056b3;
}

@media (min-width: 768px) {
  .name-fields,
  .address-fields,
  .contact-fields {
    flex-direction: row;
  }
}
