@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

h2 {
  background: linear-gradient(45deg, rgb(96, 40, 109), rgb(150, 16, 165));
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 300;
  font-size: 40px;
}

.why-join {
  padding: 40px 20px;
  background-color: #f7f7f7;
}

.why-join h2 {
  text-align: center;
}

.benefits {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.benefit {
  flex: 1 1 45%;
  padding: 20px;
  text-align: center;
}

.opportunities h2 {
  text-align: center;
}


.testimonial {
  padding: 40px 20px;
  background-color: #f7f7f7;
  text-align: center;
}
.footer {
  background-color: rgb(44, 46, 48);
  color: #fff;
  padding: 40px 10px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  margin: 0 60px;
  padding-top: 30px;
  border-top: 2px solid #777;
}

.footer-left{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-left, .footer-right {
  margin: 10px 0;
}

.footer-left a {
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
}


.footer-left a:first-child::before {
  content: '';
  margin-right: 0;
}

.footer-left a:hover {
  text-decoration: underline;
}

.footer-right {
  margin-top: 10px;
}

@media (min-width: 600px) {
  .footer-content {
    flex-direction: row;
    text-align: left;
  }

  .footer-left, .footer-right {
    margin: 0;
  }

  .footer-left a::before {
    content: '|';
    color: #fff;
    margin-right: 10px;
  }

  .footer-left{
    display: block;
  }
  
}


@media (max-width: 768px) {
  
}

@media (max-width: 576px) {
  .header-top {
    flex-direction: column;
  }

  .header-main h1 {
    font-size: 1.5rem;
  }

  .cta-button {
    padding: 10px;
  }
}
