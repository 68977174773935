.end-container {
    padding: 0 20px;
    margin-bottom: 3rem;
}

.end-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.end-text {
    order: 2; 
    max-width: 600px;
    margin-top: 20px;
    padding-bottom: 20px;
}

.end-text h3 {
    background: linear-gradient(45deg, rgb(96, 40, 109), rgb(150, 16, 165));
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 300;
    font-size: 40px;
  }

.end-image {
    order: 1;
    width: 100%;
    max-width: 600px;
}

.end-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.end-content a {
    margin-top: 20px;
    padding: 15px 145px;
    border: none;
    background-color: #008f00;
    color: white;
    text-decoration-line: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%; 
}

.end-btn:hover {
    background-color: #029b42;
}

@media (min-width: 768px) {
    .end-container {
        padding: 0 60px;
    }

    .end-content {
        flex-direction: row; 
        text-align: left;
        justify-content: space-between; 
    }

    .end-text {
        order: 1; 
        max-width: 50%;
        margin-top: 0;
    }

    .end-image {
        order: 2;
        max-width: 50%;
        margin-left: 20px;
    }

    .end-content a {
        padding: 15px 20px;
    }
}
